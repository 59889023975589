import { apiBase, customGet, customPost, customPut, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/pcs`;

export function getAllPcs() {
  return customGet(`${baseUrl}/all`);
}

export function postPcs(data) {
  return customPost(baseUrl, data);
}

export function putPcs(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deletePcsById(itemId) {
  return deleteById(baseUrl, itemId);
}
