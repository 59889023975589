import { AppBar, Button, Grid, makeStyles, Modal, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { Editor as TinyMCEEditor } from "tinymce";
import AlertDialog from "../../Components/AlertDialog";
import FormInput from "../../Components/FormInput";
import TabPanel from "../../Components/TabPanel";
import { deleteFaqById, getAllFaqs, getFaqById, postNewFaq, putFaq } from "../../utils/api/faqs";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";
import HtmlEditor from "./HtmlEditor";
import FaqTabel from "./tabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(4),
    maxWidth: 250,
  },
  modal: {
    position: "absolute",
    width: "100%",
  },
  modalContent: {
    width: "80%",
    margin: "5vh auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
}));

export const returnDenumireFromTip = (tip: number) => {
  switch (tip) {
    case 0:
      return "Platformă gazmir";
    case 1:
      return "Generale";
    case 2:
      return "Gaze Naturale";
    default:
      return "Eroare tip";
  }
};

type FaqDataDb = {
  _id?: string;
  continut: string;
  tip: number;
  titlu: string;
  isNew?: boolean;
};

const initialAddNewFaq = {
  tip: 1,
  titlu: "",
};

export default function AdministrareFAQPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // return (
  //   <Grid item xs={12} className={classes.paper}>
  //     <HtmlEditor />
  //   </Grid>
  // );
  const [value, setValue] = React.useState(0);
  const [items, setItems] = React.useState<FaqDataDb[]>([]);
  const [modalData, setModalData] = React.useState<FaqDataDb | null>(null);
  const [idFaqDeSters, setIdFaqDeSters] = React.useState<string | null>(null);

  const htmlEditorRef = React.useRef<TinyMCEEditor | null>();

  React.useEffect(() => {
    const getFaqs = async () => {
      try {
        const apiResponse = await getAllFaqs();
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
        enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      }
    };
    getFaqs();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setValue(value);
  };

  const onInitHtmlEditor = (evt: any, editor: any) => {
    htmlEditorRef.current = editor;
  };

  const onDirtyHtmlEditor = () => {};

  const onAdd = (tip: number) => {
    setModalData({
      ...initialAddNewFaq,
      tip: tip,
      isNew: true,
      continut: "",
    });
  };

  const onEdit = async (itemId: string) => {
    // setModalData(items.filter((item) => item._id === itemId)[0]);
    try {
      const apiResponse = await getFaqById(itemId);
      if (apiResponse.type) setModalData(apiResponse.data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
    }
  };

  const closeModal = () => setModalData(null);

  const changeModalData = (name: string, value: string) => {
    setModalData((oldData) =>
      oldData != null
        ? {
            ...oldData,
            [name]: value,
          }
        : null
    );
  };

  const addNewFaq = async () => {
    if (modalData != null && htmlEditorRef.current != null)
      try {
        const apiResponse = await postNewFaq({
          tip: modalData.tip,
          titlu: modalData.titlu,
          continut: htmlEditorRef.current.getContent(),
        });
        if (apiResponse.type) {
          setItems((oldItems) => [
            ...oldItems,
            { _id: apiResponse.data, tip: modalData.tip, titlu: modalData.titlu, continut: htmlEditorRef.current!.getContent() },
          ]);
          setModalData(null);
          enqueueSnackbar("Elementul a fost adăugat cu succes!", { variant: "success" });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      }
  };

  const editFaq = async () => {
    // console.log(modalData.continut);

    if (modalData != null && htmlEditorRef.current != null)
      try {
        const apiResponse = await putFaq({
          _id: modalData._id,
          tip: modalData.tip,
          titlu: modalData.titlu,
          continut: modalData.continut,
        });
        if (apiResponse.type) {
          setModalData(null);
          setItems((oldItems) => oldItems.map((item) => (item._id === modalData._id ? modalData : item)));
          enqueueSnackbar("Elementul a fost editat cu succes!", { variant: "success" });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      }
  };

  const onDeleteFaq = (itemId: string) => {
    setIdFaqDeSters(itemId);
  };
  const closeDeleteDialog = () => {
    setIdFaqDeSters(null);
  };

  const tryDeleteItem = async () => {
    try {
      const apiResponse = await deleteFaqById(idFaqDeSters);
      if (apiResponse.type) {
        enqueueSnackbar("Elementul a fost șters cu succes!", { variant: "success" });
        setItems((oldItems) => oldItems.filter((item) => item._id !== idFaqDeSters));
        setIdFaqDeSters(null);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      setIdFaqDeSters(null);
    }
  };
  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Platformă gazmir" />
          <Tab label="Generale" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FaqTabel tip={0} items={items} onAdd={onAdd} onEdit={onEdit} onDelete={onDeleteFaq} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FaqTabel tip={1} items={items} onAdd={onAdd} onEdit={onEdit} onDelete={onDeleteFaq} />
      </TabPanel>
      <Modal open={modalData != null} onClose={closeModal} disableEnforceFocus disableAutoFocus disablePortal>
        {/* @ts-ignore */}
        {modalData != null && (
          <Grid item xs={10} className={classes.modalContent}>
            <Typography variant="h5">{`${modalData.isNew ? "Adaugă" : "Editează"} faq ${returnDenumireFromTip(modalData.tip)}`}</Typography>
            <form>
              <FormInput
                className={classes.inputStyle}
                fullWidth
                onChange={changeModalData}
                label="Titlu"
                name="titlu"
                value={modalData.titlu}
                required
                regexTest={ALFA_NUMERIC_REGEX}
                helperText={ALFA_NUMERIC_ERROR}
              />
              <div className={classes.inputStyle}>
                <HtmlEditor initialValue={modalData.continut} onInit={onInitHtmlEditor} onDirty={onDirtyHtmlEditor} height={400} />
              </div>
            </form>
            <Button className={classes.button} variant="contained" color="primary" onClick={modalData.isNew ? addNewFaq : editFaq}>
              {modalData.isNew ? "Adaugă" : "Editează"}
            </Button>
          </Grid>
        )}
      </Modal>
      <AlertDialog
        open={idFaqDeSters}
        title="Vreți să ștergeți elementul?"
        message="Ștergerea acestui element va fi definită. Confirmați această ștergere"
        onAccept={tryDeleteItem}
        onCancel={closeDeleteDialog}
      />
    </div>
  );
}
