import { apiBase, customGet, customPost, customPut, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/conditiiStandard`;

export function getAllConditiiStandard() {
  return customGet(`${baseUrl}/all`);
}

export function postConditieStandard(data) {
  return customPost(baseUrl, data);
}

export function putConditieStandard(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteConditieStandardById(itemId) {
  return deleteById(baseUrl, itemId);
}
