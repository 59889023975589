import { apiBase, customDelete, customGet, customPost, customPut } from "./utils";

const baseUrl = `${apiBase}/adminApi/faqs`;

export function getAllFaqs() {
  return customGet(`${baseUrl}/all`);
}

export function getFaqById(itemId) {
  return customGet(`${baseUrl}/${itemId}`);
}

export function postNewFaq(data) {
  return customPost(baseUrl, data);
}

export function putFaq(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteFaqById(itemId) {
  return customDelete(`${baseUrl}/${itemId}`);
}
