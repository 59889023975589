import { makeStyles, Typography } from "@material-ui/core";
import { navigate } from "@reach/router";
import { useEffect } from "react";
import { appBasePath } from "../../../utils/api/utils";
import { logout } from "../../../utils/services/keepAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function LogOut() {
  const classes = useStyles();

  useEffect(() => {
    logout();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  }, []);

  return (
    <div className={classes.paper}>
      <Typography>A-ți fost delogat. În câteva momente veți fi redirecționat către pagina de login.</Typography>
    </div>
  );
}
