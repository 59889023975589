import React from "react";

export default function Spinner({ show, containerStyle, isDark = true }) {
  if (show)
    return (
      <div style={containerStyle}>
        <div className={isDark ? "sk-chase" : "sk-chase-white"}>
          <div className={isDark ? "sk-chase-dot" : "sk-chase-dot-white"}></div>
          <div className={isDark ? "sk-chase-dot" : "sk-chase-dot-white"}></div>
          <div className={isDark ? "sk-chase-dot" : "sk-chase-dot-white"}></div>
          <div className={isDark ? "sk-chase-dot" : "sk-chase-dot-white"}></div>
          <div className={isDark ? "sk-chase-dot" : "sk-chase-dot-white"}></div>
          <div className={isDark ? "sk-chase-dot" : "sk-chase-dot-white"}></div>
        </div>
      </div>
    );
  else return false;
}
