import { apiBase, customGet, customPost, customPut, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/cereriPDF`;

export function getAllCereriPDF() {
  return customGet(`${baseUrl}/all`);
}

export function postCererePDF(data) {
  return customPost(baseUrl, data);
}

export function putCererePDF(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteCererePDFById(itemId) {
  return deleteById(baseUrl, itemId);
}
