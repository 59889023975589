import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import FormInput from "../../Components/FormInput";
import { getAllConturiClienti } from "../../utils/api/conturiClienti";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(8),
  },
  saveButton: {
    alignSelf: "center",
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  searchPaper: {
    // margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  searchContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
  },
  searchInput: {
    minWidth: 300,
  },
}));

export default function ConturiClientiTabel() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const getItems = async () => {
      try {
        const apiResponse = await getAllConturiClienti(0, { searchText });
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
        enqueueSnackbar("A apărut o eroare la preluarea informațiilor", { variant: "error" });
      }
    };
    const fetchTimeout = setTimeout(getItems, 500);
    return () => {
      clearTimeout(fetchTimeout);
    };
  }, [searchText, enqueueSnackbar]);

  const changeSearchText = (name, value) => {
    setSearchText(value);
  };

  return (
    <Grid item xs={12} className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Tabel conturi clienți
      </Typography>
      <Grid item xs={12} className={classes.searchContainer}>
        <Paper className={classes.searchPaper}>
          <FormInput
            className={classes.searchInput}
            onChange={changeSearchText}
            label="Caută"
            name="cauta"
            value={searchText}
            // required
            // regexTest={ALFA_NUMERIC_REGEX}
            // helperText={ALFA_NUMERIC_ERROR}
          />
        </Paper>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Tabel conturi clienți">
          <TableHead>
            <TableRow>
              <TableCell>Denumire</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Cod abonat</TableCell>
              <TableCell>Număr contract</TableCell>
              <TableCell>Cont activ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell>{item.denumire}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.codClient}</TableCell>
                <TableCell>{item.nrContract}</TableCell>
                <TableCell>{item.active ? "Da" : "Nu"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
