import { apiBase, customGet, customPost, customPut, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/oferte`;

export function getAllOferte() {
  return customGet(`${baseUrl}/all`);
}

export function postOferta(data) {
  return customPost(baseUrl, data);
}

export function putOferta(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteOfertaById(itemId) {
  return deleteById(baseUrl, itemId);
}
