import { apiBase, customGet, customPost, customPut, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/tarife`;

export function getAllTarife() {
  return customGet(`${baseUrl}/all`);
}

export function postNewTarif(data) {
  return customPost(baseUrl, data);
}

export function putTarif(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteTarifById(itemId) {
  return deleteById(baseUrl, itemId);
}
