import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import TabPanel from "../../Components/TabPanel";
import { ActeNormative, deleteActNormativById, getAllActeNormative, postActNormativ, putActNormativ } from "../../utils/api/acteNormative";
import TabelActe from "./tabel";
import TabelRapoarte from "./tabelRapoarte";

export default function ActeNormativePage() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState<number>(0);
  const [items, setItems] = React.useState<ActeNormative[]>([]);

  React.useEffect(() => {
    const getTarife = async () => {
      try {
        const apiResponse = await getAllActeNormative();
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTarife();
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setValue(value as number);
  };

  const changeExistingEntry = (itemId: string, name: string, value: string) => {
    console.log(itemId, name, value);
    setItems((oldItems) =>
      oldItems.map((item) =>
        item._id === itemId
          ? {
              ...item,
              [name]: value,
            }
          : item
      )
    );
  };

  const addNewEntry = async (newEntryData: Omit<Omit<ActeNormative, "_id">, "tip">) => {
    try {
      const apiResponse = await postActNormativ({ ...newEntryData, tip: value });
      if (apiResponse.type) enqueueSnackbar("Inregistrarea a fost adaugată cu succes!", { variant: "success" });
      console.log(apiResponse);
      setItems((oldItems) => [...oldItems, { _id: apiResponse.data, ...newEntryData, tip: value }]);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  const editTarif = async (itemId: string) => {
    const itemToSave = items.filter((item) => item._id === itemId)[0];
    console.log(itemToSave);
    try {
      const apiResponse = await putActNormativ(itemToSave);
      if (apiResponse.type) enqueueSnackbar("Inregistrarea a fost editată cu succes!", { variant: "success" });
      console.log(apiResponse);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  const deleteTarif = async (itemId: string) => {
    const itemToSave = items.filter((item) => item._id === itemId)[0];
    console.log(itemToSave);
    try {
      const apiResponse = await deleteActNormativById(itemId);
      if (apiResponse.type) {
        setItems((oldItems) => oldItems.filter((item) => item._id !== itemId));
        enqueueSnackbar("Inregistrarea a fost stearsa cu succes!", { variant: "success" });
      }
      console.log(apiResponse);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Acte Normative" />
          <Tab label="Rapoarte" />
          <Tab label="Proceduri" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {/* <FaqTabel tip={0} items={items} onAdd={onAdd} onEdit={onEdit} /> */}
        <TabelActe
          items={items.filter((item) => item.tip === 0)}
          changeExistingEntry={changeExistingEntry}
          addNewEntry={addNewEntry}
          editEntry={editTarif}
          deleteEntry={deleteTarif}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabelRapoarte
          items={items.filter((item) => item.tip === 1)}
          changeExistingEntry={changeExistingEntry}
          addNewEntry={addNewEntry}
          editEntry={editTarif}
          deleteEntry={deleteTarif}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabelActe
          items={items.filter((item) => item.tip === 2)}
          changeExistingEntry={changeExistingEntry}
          addNewEntry={addNewEntry}
          editEntry={editTarif}
          deleteEntry={deleteTarif}
        />
      </TabPanel>
    </div>
  );
}
