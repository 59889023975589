import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { Editor as TinyMCEEditor } from "tinymce";

import {} from "@tinymce/tinymce-react";
type PropTypes = {
  initialValue: string;
  onInit: (evt: any, editor: TinyMCEEditor) => void;
  onDirty: (evt: any) => void;
  height?: string | number;
};

export default function HtmlEditor({ initialValue, onInit, onDirty, height = "70vh" }: PropTypes) {
  return (
    <Editor
      apiKey="l4b7dhajwt65oa75yu5ia975kgnt9givilu0e21fg9azapgo"
      initialValue={initialValue}
      onInit={onInit}
      onDirty={onDirty}
      init={{
        height: height,
        ontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        menubar: "file edit insert view format table tools help",
        toolbar_mode: "floating",
        file_picker_types: "image",
        image_title: true,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        block_unsupported_drop: true,
        plugins: [
          "advlist autolink lists link image",
          "charmap print preview anchor help",
          "searchreplace visualblocks code",
          "insertdatetime media table paste wordcount",
        ],
        toolbar: `undo redo | image | formatselect | fontsizeselect | bold italic | forecolor backcolor | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help`,
        file_picker_callback: function (cb: any, _value: any, _meta: any) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function () {
            var file = (this as HTMLInputElement).files?.[0];

            var reader = new FileReader();
            if (file) {
              reader.readAsDataURL(file);
            }
          };

          input.click();
        },
      }}
      // outputFormat="html"
      // onEditorChange={handleEditorChange}
      // value={value}
    />
  );
}
