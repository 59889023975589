import { Button, Grid, IconButton, makeStyles, Paper, Typography, useTheme } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "notistack";
import { Editor as TinyMCEEditor } from "tinymce";
import React from "react";
import FormInput from "../../Components/FormInput";
import Spinner from "../../Components/Spinner";
import { getEmailTemplateById, postSendTestEmail, putEmailTemplate, putImportCoduri } from "../../utils/api/emailTemplate";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX, MULTIPLE_EMAIL_ERROR, MULTIPLE_EMAIL_REGEX, NUMERIC_ERROR, NUMERIC_REGEX } from "../../utils/regex";
import HtmlEditor from "../FAQ/HtmlEditor";
import { EmailTemplate, InfoMsgType } from "../../utils/types";
import { postLinkInvite } from "../../utils/api/linkInvite";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "stretch",
    margin: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  containerPerioada: {
    display: "flex",

    justifyContent: "space-between",
  },
  buttonsContainer: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    // alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  tabList: {
    backgroundColor: "#3f51b5",
    color: "#FFFFFF",
    marginTop: theme.spacing(2),
  },
  importText: {
    padding: 8,
  },
}));

const validateFormDataTest = (testEmails: string, emailTemplate: EmailTemplate | null) => {
  if (emailTemplate == null) return false;
  return MULTIPLE_EMAIL_REGEX.test(testEmails) && emailTemplate.emailTitle != null && emailTemplate.htmlMessage != null;
};

const validateEmailTemplate = (emailTemplate: EmailTemplate) => {
  return emailTemplate.emailTitle != null && emailTemplate.htmlMessage != null;
};
const TEMPLATE_ID = "66043c983593c9ba379123a5";

type FormDataTestType = {
  testEmails: string;
  codClient: string;
  nrContract: string;
};

export default function InviteClientEmailTemplate() {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: true,
  });
  const [infoMsgSend, setInfoMsgSend] = React.useState<InfoMsgType & { isForTest: boolean }>({
    isLoading: false,
    isForTest: false,
  });
  const [emailTemplate, setEmailTemplate] = React.useState<EmailTemplate | null>(null);
  const [formData, setFormData] = React.useState<FormDataTestType>({
    testEmails: "",
    codClient: "",
    nrContract: "",
  });

  const htmlEditorRef = React.useRef<TinyMCEEditor | null>();

  React.useEffect(() => {
    const tryGetTemplateById = async () => {
      try {
        const apiResponse = await getEmailTemplateById(TEMPLATE_ID);
        console.log(apiResponse);
        if (apiResponse.type) {
          setEmailTemplate(apiResponse.data);
        } else enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      } catch (error) {
        console.error(error);
        enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      } finally {
        setInfoMsg({ isLoading: false });
      }
    };

    tryGetTemplateById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFormData = (name: string, value: string) => {
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const changeEmailTitle = (name: string, value: string) => {
    setEmailTemplate((oldData) =>
      oldData != null
        ? {
            ...oldData,
            emailTitle: value,
          }
        : null
    );
  };

  const onInitHtmlEditor = (evt: any, editor: TinyMCEEditor) => {
    htmlEditorRef.current = editor;
  };

  const onDirtyHtmlEditor = () => {};

  const tryUpdateEmailTemplate = async () => {
    if (emailTemplate != null && htmlEditorRef.current != null)
      try {
        const apiResponse = await putEmailTemplate({ ...emailTemplate, htmlMessage: htmlEditorRef.current.getContent() });
        console.log(apiResponse);
        if (apiResponse.type) enqueueSnackbar("Emailul a fost editată cu succes!", { variant: "success" });
        else enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      } catch (error) {
        enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
        console.log(error);
      }
  };

  const trySendTest = async () => {
    //TODO: Create send test route and try it
    console.log(formData);
    // console.log(emailTemplate.emailTitle, emailTemplate.htmlMessage);
    setInfoMsgSend({ isLoading: true, isForTest: true });

    try {
      const apiResponse = await postLinkInvite({ ...formData, email: formData.testEmails });
      if (apiResponse.type) enqueueSnackbar("A început transmiterea de test", { variant: "success" });
      else enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
    } finally {
      setInfoMsgSend({ isLoading: false, isForTest: false });
    }
  };

  if (emailTemplate != null) console.log(emailTemplate.htmlMessage);

  return (
    <Grid container className={classes.column}>
      <Grid xs={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" color="primary" align="center">
            Editează email început perioadă autocitire
          </Typography>
          {emailTemplate != null && [
            <FormInput
              key={1}
              id="title"
              className={classes.inputStyle}
              fullWidth
              onChange={changeEmailTitle}
              label="Titlu email"
              name="title"
              value={emailTemplate.emailTitle}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />,
            <div key={2} className={classes.inputStyle}>
              <HtmlEditor initialValue={emailTemplate.htmlMessage} onInit={onInitHtmlEditor} onDirty={onDirtyHtmlEditor} height={400} />
            </div>,
          ]}
          {infoMsg.isLoading || emailTemplate == null ? (
            <Spinner
              containerStyle={{
                width: 41,
                height: 41,
                margin: "40px auto 0px auto",
              }}
              show={true}
            />
          ) : (
            <Button
              disabled={!validateEmailTemplate(emailTemplate)}
              className={classes.saveButton}
              variant="contained"
              color="primary"
              onClick={tryUpdateEmailTemplate}
            >
              Modifică conținut email
            </Button>
          )}
        </Paper>
      </Grid>
      <Grid xs={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" color="primary" align="center">
            Testează
          </Typography>

          <form id="transmitere" className={classes.form}>
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Adrese e-mail test (separate prin virgula)"
              name="testEmails"
              value={formData.testEmails}
              required
              regexTest={MULTIPLE_EMAIL_REGEX}
              helperText={MULTIPLE_EMAIL_ERROR}
            />
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Cod client test"
              name="codClient"
              value={formData.codClient}
              required
              regexTest={NUMERIC_REGEX}
              helperText={NUMERIC_ERROR}
            />
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Nr contract test"
              name="nrContract"
              value={formData.nrContract}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />
            {infoMsgSend.isForTest && infoMsgSend.msg != null && (
              <Typography className={classes.inputStyle} color={infoMsgSend.status === "error" ? "error" : "textPrimary"}>
                {infoMsgSend.msg}
              </Typography>
            )}
            {infoMsgSend.isForTest && infoMsgSend.isLoading ? (
              <Spinner
                containerStyle={{
                  width: 41,
                  height: 41,
                  margin: "40px auto 0px auto",
                }}
                show={true}
              />
            ) : (
              <Button
                disabled={!validateFormDataTest(formData.testEmails, emailTemplate)}
                className={classes.saveButton}
                variant="contained"
                color="primary"
                onClick={trySendTest}
              >
                Testează
              </Button>
            )}
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
