export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function returnDenumireLunaByValue(luna) {
  if (luna < 0) luna = luna + 12;
  // console.log(luna, luna % 12);
  switch (luna % 12) {
    case 0:
      return "Ianuarie";
    case 1:
      return "Februarie";
    case 2:
      return "Martie";
    case 3:
      return "Aprilie";
    case 4:
      return "Mai";
    case 5:
      return "Iunie";
    case 6:
      return "Iulie";
    case 7:
      return "August";
    case 8:
      return "Septembrie";
    case 9:
      return "Octombrie";
    case 10:
      return "Noiembrie";
    case 11:
      return "Decembrie";
    default:
      return "Luna an";
  }
}
