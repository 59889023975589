import { apiBase, customDelete, customGet, customPost, customPut } from "./utils";

const baseUrl = `${apiBase}/adminApi/anunturiPublice`;

export function getAllAnunturiPublice() {
  return customGet(`${baseUrl}/all`);
}

export function getAnuntById(itemId) {
  return customGet(`${baseUrl}/${itemId}`);
}

export function postNewAnuntPublic(data) {
  return customPost(baseUrl, data);
}

export function putAnuntPublic(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteAnuntById(itemId) {
  return customDelete(`${baseUrl}/${itemId}`);
}
