import { apiBase, customGet, customPut } from "./utils";

const baseUrl = `${apiBase}/adminApi/tarifeRevizie`;

export function getTarifeRevizie() {
  return customGet(baseUrl);
}

export function putTarifeRevizie(data) {
  return customPut(`${baseUrl}/${data._id}`, data);
}
