import { Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import SaveIcon from "@material-ui/icons/Save";
import React, { useState } from "react";
import FormInput from "../../Components/FormInput";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";
import { ActeNormative } from "../../utils/api/acteNormative";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    // alignItems: "stretch",
    width: "100%",
    // flex: 1,
    // alignItems: "stretch",
    height: 800,
  },
  table: {
    minWidth: 650,
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    // margin: theme.spacing(4),
    padding: theme.spacing(8),
    // alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  saveButton: {
    alignSelf: "center",
  },
}));
const initialData: Omit<Omit<ActeNormative, "_id">, "tip"> = {
  titlu: "",
  link: "",
};

const checkNewEntry = (newEntryData: Omit<Omit<ActeNormative, "_id">, "tip">) => {
  return ALFA_NUMERIC_REGEX.test(newEntryData.titlu) && ALFA_NUMERIC_REGEX.test(newEntryData.link);
};

type PropTypes = {
  items: ActeNormative[];
  changeExistingEntry: (itemId: string, name: string, value: string) => void;
  addNewEntry: (newEntryData: Omit<Omit<ActeNormative, "_id">, "tip">) => void;
  editEntry: (itemId: string) => void;
  deleteEntry: (itemId: string) => void;
};

export default function TabelActe({ items, changeExistingEntry, addNewEntry, editEntry, deleteEntry }: PropTypes) {
  const classes = useStyles();

  const [newEntryData, setNewEntryData] = useState(initialData);

  const changeNewEntryData = (name: string, value: string) => {
    setNewEntryData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const addTarif = () => {
    setNewEntryData(initialData);
    addNewEntry(newEntryData);
  };

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Tabel acte normative
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Tabel linkuri">
              <TableHead>
                <TableRow>
                  <TableCell>Titlu</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell>Acțiuni</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <FormInput
                        className={classes.inputStyle}
                        type="text"
                        fullWidth
                        // style={{ width: 150 }}
                        onChange={(name: string, value: string) => changeExistingEntry(item._id, name, value)}
                        label="Titlu"
                        name="titlu"
                        value={item.titlu}
                        required
                        regexTest={ALFA_NUMERIC_REGEX}
                        helperText={ALFA_NUMERIC_ERROR}
                      />
                    </TableCell>
                    {/* <TableCell>{produsNewEntry.nrUnitati}</TableCell> */}
                    <TableCell>
                      <FormInput
                        className={classes.inputStyle}
                        type="text"
                        // style={{ width: 150 }}
                        fullWidth
                        onChange={(name: string, value: string) => changeExistingEntry(item._id, name, value)}
                        label="Link fișier"
                        name="link"
                        value={item.link}
                        required
                        regexTest={ALFA_NUMERIC_REGEX}
                        helperText={ALFA_NUMERIC_ERROR}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" component="span" aria-label="editează înregistrare" onClick={() => editEntry(item._id)}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton color="primary" component="span" aria-label="șterge înregistrare" onClick={() => deleteEntry(item._id)}>
                        <DeleteRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow key={items.length}>
                  <TableCell>
                    <FormInput
                      className={classes.inputStyle}
                      type="text"
                      fullWidth
                      // style={{ width: 150 }}
                      onChange={changeNewEntryData}
                      label="Titlu"
                      name="titlu"
                      value={newEntryData.titlu}
                      required
                      regexTest={ALFA_NUMERIC_REGEX}
                      helperText={ALFA_NUMERIC_ERROR}
                    />
                  </TableCell>
                  {/* <TableCell>{produsNewEntry.nrUnitati}</TableCell> */}
                  <TableCell>
                    <FormInput
                      className={classes.inputStyle}
                      type="text"
                      // style={{ width: 150 }}
                      fullWidth
                      onChange={changeNewEntryData}
                      label="Link fișier"
                      name="link"
                      value={newEntryData.link}
                      required
                      regexTest={ALFA_NUMERIC_REGEX}
                      helperText={ALFA_NUMERIC_ERROR}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="adaugă înregistrare"
                      onClick={() => addTarif()}
                      disabled={!checkNewEntry(newEntryData)}
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
