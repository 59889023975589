import { getToken } from "../services/keepAuth";
import { apiBase, checkStatusForBlob } from "./utils";

const baseUrl = `${apiBase}/adminApi/export`;

export function getExportFormularContact() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/formularContact`, loginData).then(checkStatusForBlob);
}

export function getExportPlangeriSesizari() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/plangeriSesizari`, loginData).then(checkStatusForBlob);
}

export function getExportCerereAudienta() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/cerereAudienta`, loginData).then(checkStatusForBlob);
}

export function getExportCerereAvizAmplasament() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/cerereAvizAmplasament`, loginData).then(checkStatusForBlob);
}

export function getExportCerereContractFurnizare() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/cerereContractFurnizare`, loginData).then(checkStatusForBlob);
}

export function getExportDoveziMentenanta() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/doveziRevizii`, loginData).then(checkStatusForBlob);
}

export function getExportDuplicatContract() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/duplicatContract`, loginData).then(checkStatusForBlob);
}

export function getExportProgramariRevizii() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/programariRevizii`, loginData).then(checkStatusForBlob);
}

export function getExportSchimbareAdministrativa() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/schimbareAdministrativa`, loginData).then(checkStatusForBlob);
}

export function getExportSchimbareTitular() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/schimbareTitular`, loginData).then(checkStatusForBlob);
}

export function getExportVerificareContor() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/verificareContor`, loginData).then(checkStatusForBlob);
}

export function getExportEmailuriAdmin() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/emailuriAdmin`, loginData).then(checkStatusForBlob);
}

export function getExportConturiClienti() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/conturiClienti`, loginData).then(checkStatusForBlob);
}

export function getExportInvitatiiCreareCont() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/linkInviteClienti`, loginData).then(checkStatusForBlob);
}

export function getExportCereriRacordare() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/cereriRacordare`, loginData).then(checkStatusForBlob);
}

export function getExportCoduriClientiNotificareAutocitire() {
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/coduriClientiNotificareAutocitire`, loginData).then(checkStatusForBlob);
}
