import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import FormInput from "../../Components/FormInput";
import { getTarifeRevizie, putTarifeRevizie } from "../../utils/api/tarifeRevizii";
import { FLOAT_ERROR, FLOAT_REGEX } from "../../utils/regex";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonsContainer: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    // alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    alignSelf: "center",
  },
  button: {
    margin: theme.spacing(1),
    maxWidth: 250,
  },
  deleteButton: {
    marginLeft: theme.spacing(4),
  },
}));

const validateFormData = (tarife) =>
  tarife.vtpIu &&
  FLOAT_REGEX.test(tarife.vtpIu.casnic) &&
  FLOAT_REGEX.test(tarife.vtpIu.nonCasnic) &&
  tarife.revizieIu &&
  FLOAT_REGEX.test(tarife.revizieIu.casnic) &&
  FLOAT_REGEX.test(tarife.revizieIu.nonCasnic);

export default function TarifeReviziePage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // return (
  //   <Grid item xs={12} className={classes.paper}>
  //     <HtmlEditor />
  //   </Grid>
  // );
  const [tarife, setTarife] = useState(null);

  useEffect(() => {
    const getTarife = async () => {
      try {
        const apiResponse = await getTarifeRevizie();
        if (apiResponse.data) setTarife(apiResponse.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTarife();
  }, []);

  const changeTarifVtp = (name, value) => {
    setTarife((oldTarife) => ({
      _id: oldTarife._id,
      vtpIu: {
        ...oldTarife.vtpIu,
        [name]: value,
      },
      revizieIu: oldTarife.revizieIu,
    }));
  };

  const changeTarifRevizie = (name, value) => {
    setTarife((oldTarife) => ({
      _id: oldTarife._id,
      vtpIu: oldTarife.vtpIu,
      revizieIu: {
        ...oldTarife.revizieIu,
        [name]: value,
      },
    }));
  };

  const editTarife = async () => {
    try {
      const apiResponse = await putTarifeRevizie(tarife);
      if (apiResponse.type) enqueueSnackbar("Tarifele pentru revizii au fost updatate!", { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
    }
  };

  if (tarife)
    return (
      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <form id="vtpIu">
              <FormInput
                className={classes.inputStyle}
                fullWidth
                onChange={changeTarifVtp}
                label="Tarif vtp casnic"
                name="casnic"
                value={tarife.vtpIu.casnic}
                required
                regexTest={FLOAT_REGEX}
                helperText={FLOAT_ERROR}
              />
              <FormInput
                className={classes.inputStyle}
                fullWidth
                onChange={changeTarifVtp}
                label="Tarif vtp non casnic"
                name="nonCasnic"
                value={tarife.vtpIu.nonCasnic}
                required
                regexTest={FLOAT_REGEX}
                helperText={FLOAT_ERROR}
              />
            </form>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <form id="reviziiIu">
              <FormInput
                className={classes.inputStyle}
                fullWidth
                onChange={changeTarifRevizie}
                label="Tarif revizie casnic"
                name="casnic"
                value={tarife.revizieIu.casnic}
                required
                regexTest={FLOAT_REGEX}
                helperText={FLOAT_ERROR}
              />
              <FormInput
                className={classes.inputStyle}
                fullWidth
                onChange={changeTarifRevizie}
                label="Tarif revizie non casnic"
                name="nonCasnic"
                value={tarife.revizieIu.nonCasnic}
                required
                regexTest={FLOAT_REGEX}
                helperText={FLOAT_ERROR}
              />
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.buttonsContainer}>
          <Button disabled={!validateFormData(tarife)} className={classes.saveButton} variant="contained" color="primary" onClick={editTarife}>
            Modifică
          </Button>
        </Grid>
      </Grid>
    );
  else return false;
}
