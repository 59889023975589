import { checkStatus, apiBase } from "./utils";

export function postLogin(data) {
  const requestOptions = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiBase}/adminLogin`, requestOptions).then(checkStatus);
}
