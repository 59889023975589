import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GavelRoundedIcon from "@material-ui/icons/GavelRounded";
import EmailIcon from "@material-ui/icons/Email";
import HelpIcon from "@material-ui/icons/Help";
import MoneyIcon from "@material-ui/icons/Money";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PeopleIcon from "@material-ui/icons/People";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ListIcon from "@material-ui/icons/List";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import DraftsIcon from "@material-ui/icons/Drafts";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link, LinkGetProps } from "@reach/router";
import React from "react";
import { appBasePath } from "../../utils/api/utils";

type PropTypes = {
  isActive: ({ isCurrent }: LinkGetProps) => { className?: string };
};

export const AdminListItems = ({ isActive }: PropTypes) => (
  <div>
    {/* <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard`}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Acasă" />
      </ListItem>
    </Link> */}

    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/creareCont`}>
      <ListItem button>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Creare cont client" />
      </ListItem>
    </Link>

    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/transmitereFacturi`}>
      <ListItem button>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Emailuri facturi" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/emailuriAutomate`}>
      <ListItem button>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Emailuri automate" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/conturiClienti`}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Conturi clienti" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/faq`}>
      <ListItem button>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Administrare FAQ" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/tarife`}>
      <ListItem button>
        <ListItemIcon>
          <MoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Administrare Tarife" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/tarifeRevizie`}>
      <ListItem button>
        <ListItemIcon>
          <MoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Tarife Revizii" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/acteNormative`}>
      <ListItem button>
        <ListItemIcon>
          <GavelRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Acte Normative" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/pcs`}>
      <ListItem button>
        <ListItemIcon>
          <EqualizerIcon />
        </ListItemIcon>
        <ListItemText primary="P.C.S." />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/oferte`}>
      <ListItem button>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="Oferte" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/cereriPDF`}>
      <ListItem button>
        <ListItemIcon>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText primary="Cereri PDF" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/conditiiStandard`}>
      <ListItem button>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="ConditiiStandard" />
      </ListItem>
    </Link>
    {/* <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/emailuriClienti`}>
      <ListItem button>
        <ListItemIcon>
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText primary="Emailuri clienti" />
      </ListItem>
    </Link> */}
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/preavize`}>
      <ListItem button>
        <ListItemIcon>
          <MoneyOffIcon />
        </ListItemIcon>
        <ListItemText primary="Import preavize" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/accesConsumuri`}>
      <ListItem button>
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        <ListItemText primary="Acces consumuri" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/anunturiPublice`}>
      <ListItem button>
        <ListItemIcon>
          <AnnouncementIcon />
        </ListItemIcon>
        <ListItemText primary="Anunțuri publice" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/autocitiri`}>
      <ListItem button>
        <ListItemIcon>
          <LocalGasStationIcon />
        </ListItemIcon>
        <ListItemText primary="Export autocitiri" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/export`}>
      <ListItem button>
        <ListItemIcon>
          <ImportExportIcon />
        </ListItemIcon>
        <ListItemText primary="Export" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/logout`}>
      <ListItem button>
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary="Ieșire" />
      </ListItem>
    </Link>
    {/* 
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const CaserieListItems = ({ isActive }: PropTypes) => (
  <div>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/creareCont`}>
      <ListItem button>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Creare cont client" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/conturiClienti`}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Conturi clienti" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/exportCaserie`}>
      <ListItem button>
        <ListItemIcon>
          <ImportExportIcon />
        </ListItemIcon>
        <ListItemText primary="Export" />
      </ListItem>
    </Link>
    <Link getProps={isActive} className="noDecorationsLink" to={`${appBasePath}/dashboard/logout`}>
      <ListItem button>
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary="Ieșire" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
