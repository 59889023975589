import { ApiResponse, apiBase, convertFilterToRequestParams, customGet, customPost } from "./utils";

const baseUrl = `${apiBase}/adminApi/linkInvite`;

type PostInviteClientData = {
  email: string;
  codClient: string;
  nrContract: string;
};

interface PostLinkInvite extends ApiResponse<null> {
  contWithCodClientExists?: boolean;
  contWithEmailExists?: boolean;
  contWithNrContractExists?: boolean;
}

export function postLinkInvite(data: PostInviteClientData): Promise<PostLinkInvite> {
  return customPost(`${baseUrl}`, data);
}
