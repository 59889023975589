import { getToken } from "../services/keepAuth";
import { ApiResponse, apiBase, checkStatus, checkStatusForBlob } from "./utils";

const baseUrl = `${apiBase}/adminApi/transmitereEmailFacturi`;

export function getRaportEmailuriFacturi(filters: any) {
  let params = "";
  if (filters)
    params = Object.keys(filters)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
      .join("&")
      .replace(/%20/g, "+");
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/raportTransmitere?${params}`, loginData).then(checkStatusForBlob);
}

export function postImportEmailuriClienți(data: FormData): Promise<ApiResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
    },
    body: data,
  };
  return fetch(`${baseUrl}/importEmailuri`, loginData).then(checkStatus) as Promise<ApiResponse<null>>;
}

export type ApiResponeCountFacturi = {
  totalFacturi: number;
  totalEmailuri: number;
  totalFacturiCuClientiCuEmail: number;
  tipuriEmailuri: string[];
};

export function getCountFacturiDeTrimis(filters: any): Promise<ApiResponse<ApiResponeCountFacturi>> {
  let params = "";
  if (filters)
    params = Object.keys(filters)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
      .join("&")
      .replace(/%20/g, "+");
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/countFacturiDeTrimis?${params}`, loginData).then(checkStatus) as Promise<ApiResponse<ApiResponeCountFacturi>>;
}

export function postTestTransmitere(bodyData: any): Promise<ApiResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  };
  return fetch(`${baseUrl}/testTransmitere`, loginData).then(checkStatus) as Promise<ApiResponse<null>>;
}

export function postStartTransmitere(bodyData: any): Promise<ApiResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
  };
  return fetch(`${baseUrl}/startTransmitere`, loginData).then(checkStatus) as Promise<ApiResponse<null>>;
}
