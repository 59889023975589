import FileSaver from "file-saver";
import { Button, Grid, Paper, makeStyles } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { getExportInvitatiiCreareCont } from "../../utils/api/export";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(4),
    maxWidth: 250,
    minHeight: 60,
  },
}));

export default function ExportCaseriePage() {
  const classes = useStyles();

  const exportInvitatiiCreareCont = async () => {
    try {
      const data = await getExportInvitatiiCreareCont();
      if (data) FileSaver.saveAs(data, "ExportInvitatiiCreareCont.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container className={classes.column}>
      <Grid item xs={8}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportInvitatiiCreareCont}>
            Export invitații creare cont
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
