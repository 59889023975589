import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TabPanel from "../../Components/TabPanel";
import ConturiClientiTabel from "./tabel";
import VerificareSivecoCodClient from "./VerificareSivecoCodClient";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(4),
    maxWidth: 250,
  },
  modal: {
    position: "absolute",
    width: "100%",
  },
  modalContent: {
    width: "80%",
    margin: "5vh auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
}));

export default function ConturiClientiPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Tabel conturi clienți" />
          <Tab label="Verificare codClient și nrContract Siveco" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <ConturiClientiTabel />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <VerificareSivecoCodClient />
      </TabPanel>
    </div>
  );
}
