import { apiBase, convertFilterToRequestParams, customGet } from "./utils";

const baseUrl = `${apiBase}/adminApi/conturiClienti`;

export function getAllConturiClienti(pageNumber, filters) {
  const params = convertFilterToRequestParams(filters);
  return customGet(`${baseUrl}/all/pageNumber?${params}`);
}

export function getCheckCodClient({ codClient, nrContract }) {
  return customGet(`${baseUrl}/checkCodClient/${codClient}/${nrContract}`);
}
