import { getToken } from "../services/keepAuth";
import { apiBase, checkStatusForBlob } from "./utils";

const baseUrl = `${apiBase}/adminApi/autocitiri`;

export function getRaportAutocitiri(filters) {
  let params = "";
  if (filters)
    params = Object.keys(filters)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
      .join("&")
      .replace(/%20/g, "+");
  const loginData = {
    method: "get",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${baseUrl}/export?${params}`, loginData).then(checkStatusForBlob);
}
