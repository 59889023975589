import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { deleteOfertaById, getAllOferte, postOferta, putOferta } from "../../utils/api/oferte";
import TabelOferte from "./tabel";

export default function OfertePage() {
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getTarife = async () => {
      try {
        const apiResponse = await getAllOferte();
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTarife();
  }, []);

  const changeExistingEntry = (itemId, name, value) => {
    console.log(itemId, name, value);
    setItems((oldItems) =>
      oldItems.map((item) =>
        item._id === itemId
          ? {
              ...item,
              [name]: value,
            }
          : item
      )
    );
  };

  const addNewEntry = async (newEntryData) => {
    console.log(newEntryData);
    try {
      const apiResponse = await postOferta(newEntryData);
      if (apiResponse.type) enqueueSnackbar("Inregistrarea a fost adaugată cu succes!", { variant: "success" });
      console.log(apiResponse);
      setItems((oldItems) => [...oldItems, { _id: apiResponse.data, ...newEntryData }]);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  const editTarif = async (itemId) => {
    const itemToSave = items.filter((item) => item._id === itemId)[0];
    console.log(itemToSave);
    try {
      const apiResponse = await putOferta(itemToSave);
      if (apiResponse.type) enqueueSnackbar("Inregistrarea a fost editată cu succes!", { variant: "success" });
      console.log(apiResponse);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  const deleteTarif = async (itemId) => {
    const itemToSave = items.filter((item) => item._id === itemId)[0];
    console.log(itemToSave);
    try {
      const apiResponse = await deleteOfertaById(itemId);
      if (apiResponse.type) {
        setItems((oldItems) => oldItems.filter((item) => item._id !== itemId));
        enqueueSnackbar("Inregistrarea a fost stearsa cu succes!", { variant: "success" });
      }
      console.log(apiResponse);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <div>
      <TabelOferte
        items={items}
        changeExistingEntry={changeExistingEntry}
        addNewEntry={addNewEntry}
        editEntry={editTarif}
        deleteEntry={deleteTarif}
      />
    </div>
  );
}
