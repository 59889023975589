export const ALFA_NUMERIC_REGEX = new RegExp("^(?!s*$).+");

export const ALFA_NUMERIC_ERROR = "Introduceți doar caractere alfa-numerice";

export const NUMERIC_REGEX = new RegExp("^\\d+$");

export const NUMERIC_ERROR = "Introduceți un număr întreg";

export const FLOAT_REGEX = new RegExp("^[+-]?\\d+(\\.\\d+)?$");

export const FLOAT_ERROR = "Introduceți o valoare numerică";

export const PROCENT_REGEX = new RegExp("^(0(.\\d+)?|1.0)$");

export const PROCENT_ERROR = "Introduceți o valoare între 0-1";

export const EMAIL_REGEX =
  //eslint-disable-next-line
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const EMAIL_ERROR = "Introduceți o adresă de email validă";

export const MULTIPLE_EMAIL_REGEX = {
  test: (value) => {
    if (value != null && typeof value === "string" && value.length > 0) {
      const commaSplit = value.split(",");
      for (const i of commaSplit) if (!EMAIL_REGEX.test(i)) return false;
      return true;
    } else return false;
  },
};

export const MULTIPLE_EMAIL_ERROR = "Introduceți adrese de email valide separate prin virgulă";

export const MULTIPLE_CODCLIENT_REGEX = {
  test: (value) => {
    if (value != null && typeof value === "string" && value.length > 0) {
      const commaSplit = value.split(",");
      for (const i of commaSplit) if (!NUMERIC_REGEX.test(i)) return false;
      return true;
    } else return false;
  },
};

export const MULTIPLE_CODCLIENT_ERROR = "Introduceți coduri de client valide separate prin virgulă";
