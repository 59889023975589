// @ts-nocheck
import React from "react";
import { Router, navigate } from "@reach/router";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import "./App.css";
import AccesConsumuriPage from "./Pages/AccesConsumuri";
import ActeNormativePage from "./Pages/ActeNormative";
import ConditiiStandardPage from "./Pages/ConditiiStandard";
import Dash from "./Pages/Dash";
import LogOut from "./Pages/Dash/LogOut";
import ExportPage from "./Pages/Export";
import AdministrareFAQPage from "./Pages/FAQ";
import Home from "./Pages/Home";
import OfertePage from "./Pages/Oferte";
import PCSPage from "./Pages/PCS";
import PreavizePage from "./Pages/Preavize";
import AdministrareTarifePage from "./Pages/Tarife";
import TarifeReviziePage from "./Pages/TarifeRevizii";
import AnunturiPublicePage from "./Pages/AnunturiPublice";
import ConturiClientiPage from "./Pages/ConturiClienti";
import AutocitirePage from "./Pages/Autocitire";
import CereriPage from "./Pages/CereriPDF";
import EmailuriAutomatePage from "./Pages/EmailuriAutomate";
import CreareContPage from "./Pages/CreareCont";
import TransmitereEmailuriPage from "./Pages/TransmitereFacturi";
import { AdminRole, getAdminInfo, logout } from "./utils/services/keepAuth";
import ExportCaseriePage from "./Pages/ExportCaserie";

export default function App() {
  const [adminRole, setAdminRole] = React.useState<AdminRole | null>(null);

  React.useEffect(() => {
    const adminInfo = getAdminInfo();
    if (adminInfo != null) setAdminRole(adminInfo.role);
    else {
      logout();
      navigate("/");
    }
  }, []);

  return (
    <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }} maxSnack={3} autoHideDuration={5000}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router basepath="">
          <Home path="/" exact setAdminRole={setAdminRole} />
          {adminRole != null &&
            (adminRole === AdminRole.Caserie ? (
              <Dash adminRole={adminRole} path="dashboard">
                <ConturiClientiPage path="conturiClienti" />
                <CreareContPage path="creareCont" />
                <ExportCaseriePage path="exportCaserie" />
                {/* <EmailuriClientiPage path="emailuriClienti" /> */}
                <LogOut path="logout" />
              </Dash>
            ) : (
              <Dash adminRole={adminRole} path="dashboard">
                <TransmitereEmailuriPage path="transmitereFacturi" />
                <EmailuriAutomatePage path="emailuriAutomate" />
                <AdministrareFAQPage path="faq" />
                <AdministrareTarifePage path="tarife" />
                <TarifeReviziePage path="tarifeRevizie" />
                <ActeNormativePage path="acteNormative" />
                <PCSPage path="pcs" />
                <OfertePage path="oferte" />
                <CereriPage path="cereriPDF" />
                <ConditiiStandardPage path="conditiiStandard" />
                <PreavizePage path="preavize" />
                <ExportPage path="export" />
                <AccesConsumuriPage path="accesConsumuri" />
                <AnunturiPublicePage path="anunturiPublice" />
                <ConturiClientiPage path="conturiClienti" />
                <CreareContPage path="creareCont" />
                <AutocitirePage path="autocitiri" />
                {/* <EmailuriClientiPage path="emailuriClienti" /> */}
                <LogOut path="logout" />
              </Dash>
            ))}
        </Router>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}
