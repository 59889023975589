import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TabPanel from "../../Components/TabPanel";
import ImportExportTransmiteremailuri from "./ImportExport";
import StartSendEmailuriFacturi from "./StartSend";

export default function TransmitereEmailuriPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Import/Export" />
          <Tab label="Începe transmitere emailrui" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <ImportExportTransmiteremailuri />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <StartSendEmailuriFacturi />
      </TabPanel>
    </div>
  );
}
