import { getToken } from "../services/keepAuth";
import { ApiResponse, apiBase, checkStatus } from "./utils";

const baseUrl = `${apiBase}/adminApi/accesConsumuri`;

export async function postImportAccesConsumuri(data: FormData): Promise<ApiResponse<null>> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
    },
    body: data,
  };
  return fetch(`${baseUrl}`, loginData).then(checkStatus) as Promise<ApiResponse<null>>;
}
