import { TextField } from "@material-ui/core";
import React from "react";

type PropTypes = {
  id?: string;
  name: string;
  value: string;
  type?: string;
  onChange: (name: string, value: string) => void;
  className?: string;
  fullWidth?: boolean;
  required?: boolean;
  label: string;
  regexTest?: { test: (value: string) => boolean };
  helperText?: string;
};

export default function FormInput({
  id,
  name,
  value,
  onChange,
  className,
  fullWidth = false,
  required = false,
  label,
  regexTest,
  helperText,
  ...rest
}: PropTypes) {
  const [error, setError] = React.useState(false);

  const testInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;

    // if (regexTest) setError(!regexTest.test(value));
    onChange(name, value);
  };

  React.useEffect(() => {
    if (value === "")
      if (required) setError(true);
      else setError(false);
    else if (regexTest) setError(!regexTest.test(value));
  }, [value, regexTest]);

  return (
    <TextField
      className={className}
      fullWidth={fullWidth}
      id={id ?? name + Math.round(Math.random() * 1000)}
      variant="outlined"
      onChange={testInput}
      label={label}
      name={name}
      value={value}
      required={required}
      error={error}
      helperText={error ? helperText : undefined}
      {...rest}
    />
  );
}
