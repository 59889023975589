import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import TabPanel from "../../Components/TabPanel";
import { deleteTarifById, getAllTarife, postNewTarif, putTarif } from "../../utils/api/tarife";
import TabelTarife from "./tabel";

export default function AdministrareTarifePage() {
  const { enqueueSnackbar } = useSnackbar();
  // return (
  //   <Grid item xs={12} className={classes.paper}>
  //     <HtmlEditor />
  //   </Grid>
  // );
  const [value, setValue] = useState(0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getTarife = async () => {
      try {
        const apiResponse = await getAllTarife();
        if (apiResponse.data) setItems(apiResponse.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTarife();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeExistingEntry = (itemId, name, value) => {
    setItems((oldItems) =>
      oldItems.map((item) =>
        item._id === itemId
          ? {
              ...item,
              [name]: value,
            }
          : item
      )
    );
  };

  const addNewEntry = async (newEntryData) => {
    newEntryData.tip = value;
    try {
      const apiResponse = await postNewTarif(newEntryData);
      if (apiResponse.type) enqueueSnackbar("Inregistrarea a fost adaugată cu succes!", { variant: "success" });
      console.log(apiResponse);
      setItems((oldItems) => [...oldItems, { _id: apiResponse.data, tip: newEntryData.tip, titlu: newEntryData.titlu, link: newEntryData.link }]);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  const editTarif = async (itemId) => {
    const itemToSave = items.filter((item) => item._id === itemId)[0];
    console.log(itemToSave);
    try {
      const apiResponse = await putTarif(itemToSave);
      if (apiResponse.type) enqueueSnackbar("Inregistrarea a fost editată cu succes!", { variant: "success" });
      console.log(apiResponse);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  const deleteTarif = async (itemId) => {
    const itemToSave = items.filter((item) => item._id === itemId)[0];
    console.log(itemToSave);
    try {
      const apiResponse = await deleteTarifById(itemId);
      if (apiResponse.type) {
        setItems((oldItems) => oldItems.filter((item) => item._id !== itemId));
        enqueueSnackbar("Inregistrarea a fost stearsa cu succes!", { variant: "success" });
      }
      console.log(apiResponse);
    } catch (error) {
      enqueueSnackbar("A apărut o eroare! Vă rugăm reîncercați", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {/* <Tab label="Tarife furnizare" /> */}
          <Tab label="Tarife distribuție" />
          <Tab label="Tarife activități conexe" />
        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <TabelTarife
          items={items.filter((item) => item.tip === 0)}
          changeExistingEntry={changeExistingEntry}
          addNewEntry={addNewEntry}
          editEntry={editTarif}
          deleteEntry={deleteTarif}
        />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <TabelTarife
          items={items.filter((item) => item.tip === 0)}
          changeExistingEntry={changeExistingEntry}
          addNewEntry={addNewEntry}
          editEntry={editTarif}
          deleteEntry={deleteTarif}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabelTarife
          items={items.filter((item) => item.tip === 1)}
          changeExistingEntry={changeExistingEntry}
          addNewEntry={addNewEntry}
          editEntry={editTarif}
          deleteEntry={deleteTarif}
        />
      </TabPanel>
    </div>
  );
}
