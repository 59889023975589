import { Button, makeStyles } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { DatePicker } from "@mui/x-date-pickers";
import FileSaver from "file-saver";
import React from "react";
import { getRaportAutocitiri } from "../../utils/api/autocitiri";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
    maxWidth: 250,
  },
}));

const dataIntaiALunii = new Date();
dataIntaiALunii.setHours(10);
dataIntaiALunii.setDate(1);

export default function AutocitirePage() {
  const classes = useStyles();
  const [dataEmitere, setDataEmitere] = React.useState(dataIntaiALunii);

  const downloadRaportEmailuriFacturi = async () => {
    try {
      const data = await getRaportAutocitiri({ luna: dataEmitere.getMonth() + 1, an: dataEmitere.getFullYear() });
      if (data) FileSaver.saveAs(data, "exportAutocitiri");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDataEmitereChange = (date: Date | null) => {
    console.log(date);
    if (date != null) setDataEmitere(date);
  };

  return (
    <div className={classes.paper}>
      <DatePicker
        // disableToolbar
        views={["year", "month"]}
        openTo="month"
        // variant="inline"
        format="MMM-yyyy"
        // margin="normal"
        // id="date-picker-inline"
        label="Luna autocitiri"
        value={dataEmitere}
        onChange={handleDataEmitereChange}
      />
      <Button className={classes.button} variant="contained" color="primary" endIcon={<DescriptionIcon />} onClick={downloadRaportEmailuriFacturi}>
        Export autocitiri
      </Button>
    </div>
  );
}
