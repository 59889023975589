import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FileSaver from "file-saver";
import React from "react";
import {
  getExportCerereAudienta,
  getExportFormularContact,
  getExportPlangeriSesizari,
  getExportCerereAvizAmplasament,
  getExportCerereContractFurnizare,
  getExportDoveziMentenanta,
  getExportDuplicatContract,
  getExportProgramariRevizii,
  getExportSchimbareAdministrativa,
  getExportSchimbareTitular,
  getExportVerificareContor,
  getExportEmailuriAdmin,
  getExportConturiClienti,
  getExportInvitatiiCreareCont,
  getExportCereriRacordare,
  getExportCoduriClientiNotificareAutocitire,
} from "../../utils/api/export";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(4),
    maxWidth: 250,
    minHeight: 60,
  },
  fileName: {
    // padding: theme.spacing(1),
    fontSize: 20,
    lineHeight: "48px",
    fontWeight: 500,
  },
  closeContainer: {
    flex: 2,
  },
}));

export default function ExportPage() {
  const classes = useStyles();

  const exportFormularContact = async () => {
    try {
      const data = await getExportFormularContact();
      if (data) FileSaver.saveAs(data, "ExportFormularContact.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportPlangeriSesizari = async () => {
    try {
      const data = await getExportPlangeriSesizari();
      if (data) FileSaver.saveAs(data, "ExportPlangeriSesizari.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportCerereAudienta = async () => {
    try {
      const data = await getExportCerereAudienta();
      if (data) FileSaver.saveAs(data, "ExportCereriAudienta.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportCerereAvizAmplasament = async () => {
    try {
      const data = await getExportCerereAvizAmplasament();
      if (data) FileSaver.saveAs(data, "ExportCereriAvizAmplasament.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportCerereContractFurnizare = async () => {
    try {
      const data = await getExportCerereContractFurnizare();
      if (data) FileSaver.saveAs(data, "ExportCereriContractFurnizare.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportDoveziMentenanta = async () => {
    try {
      const data = await getExportDoveziMentenanta();
      if (data) FileSaver.saveAs(data, "ExportDoveziMentenanta.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportDuplicatContract = async () => {
    try {
      const data = await getExportDuplicatContract();
      if (data) FileSaver.saveAs(data, "ExportDuplicatContract.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportProgramariRevizii = async () => {
    try {
      const data = await getExportProgramariRevizii();
      if (data) FileSaver.saveAs(data, "ExportProgramariRevizii.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportSchimbareAdministrativa = async () => {
    try {
      const data = await getExportSchimbareAdministrativa();
      if (data) FileSaver.saveAs(data, "ExportSchimbariAdministrative.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportSchimbareTitular = async () => {
    try {
      const data = await getExportSchimbareTitular();
      if (data) FileSaver.saveAs(data, "ExportSchimbariTitular.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportVerificareContor = async () => {
    try {
      const data = await getExportVerificareContor();
      if (data) FileSaver.saveAs(data, "ExportVerificariContor.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportEmailuriAdmin = async () => {
    try {
      const data = await getExportEmailuriAdmin();
      if (data) FileSaver.saveAs(data, "ExportEmailuriAdmin.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportConturiClienti = async () => {
    try {
      const data = await getExportConturiClienti();
      if (data) FileSaver.saveAs(data, "ExportConturiClienti.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportInvitatiiCreareCont = async () => {
    try {
      const data = await getExportInvitatiiCreareCont();
      if (data) FileSaver.saveAs(data, "ExportInvitatiiCreareCont.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportCereriRacordare = async () => {
    try {
      const data = await getExportCereriRacordare();
      if (data) FileSaver.saveAs(data, "ExportCereriRacordare.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const exportEmailuriNotificareInceperePerioadaAutocitirii = async () => {
    try {
      const data = await getExportCoduriClientiNotificareAutocitire();
      if (data) FileSaver.saveAs(data, "ExportCoduriClientiNotificareAutocitire.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container className={classes.column}>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportFormularContact}>
            Export formular contact
          </Button>
        </Paper>
      </Grid>
      {/* <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportPlangeriSesizari}>
            Export plângeri și sesizări
          </Button>
        </Paper>
      </Grid> */}
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportCerereAudienta}>
            Export cereri audienta
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={<CloudDownloadIcon />}
            onClick={exportCerereAvizAmplasament}
          >
            Export cereri aviz amplasament
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={<CloudDownloadIcon />}
            onClick={exportCerereContractFurnizare}
          >
            Export cereri contract furnizare
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportDoveziMentenanta}>
            Export dovezi mentenanta
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportDuplicatContract}>
            Export duplicat contract
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportProgramariRevizii}>
            Export programari revizii
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={<CloudDownloadIcon />}
            onClick={exportSchimbareAdministrativa}
          >
            Export schimbari administrative
          </Button>
        </Paper>
      </Grid>
      {/* <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportSchimbareTitular}>
            Export schimbari nume titular
          </Button>
        </Paper>
      </Grid> */}
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportVerificareContor}>
            Export verificari contor metrologie
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportEmailuriAdmin}>
            Export emailuri admin
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportConturiClienti}>
            Export conturi clienti
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportInvitatiiCreareCont}>
            Export invitații creare cont
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<CloudDownloadIcon />} onClick={exportCereriRacordare}>
            Export cereri racordare
          </Button>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={<CloudDownloadIcon />}
            onClick={exportEmailuriNotificareInceperePerioadaAutocitirii}
          >
            Export emailuri notificare începere perioadă autocitirii
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
