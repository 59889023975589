let memoryToken: string | null;

export function saveToken(token: string, keepAuth: boolean) {
  if (token && keepAuth) {
    memoryToken = token;
    localStorage.setItem("token", token);
  } else if (token) memoryToken = token;
}

export function getToken() {
  if (memoryToken == null) {
    memoryToken = localStorage.getItem("token");
  }
  return memoryToken;
}

export function isLoggedIn() {
  return memoryToken || localStorage.getItem("token");
}

export function logout() {
  memoryToken = null;
  localStorage.removeItem("token");
  localStorage.removeItem("adminInfo");
}

export enum AdminRole {
  Admin = "admin",
  Caserie = "caserie",
}

export type AdminInfo = {
  _id: string;
  email: string;
  role: AdminRole;
};
export function saveAdminInfo(adminInfo: AdminInfo) {
  localStorage.setItem("adminInfo", JSON.stringify(adminInfo));
}

export function getAdminInfo(): AdminInfo | null {
  const adminInfo = localStorage.getItem("adminInfo");
  return adminInfo ? JSON.parse(adminInfo) : null;
}
