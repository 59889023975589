import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { returnDenumireFromTip } from ".";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(8),
  },
  saveButton: {
    alignSelf: "center",
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

export default function FaqTabel({ tip, items, onAdd, onEdit, onDelete }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <Typography variant="h4" gutterBottom>
        {`Tabel faqs ${returnDenumireFromTip(tip)}`}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Tabel faqs">
          <TableHead>
            <TableRow>
              <TableCell>Titlu</TableCell>
              <TableCell>Acțiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .filter((item) => item.tip === tip)
              .map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{item.titlu}</TableCell>
                  <TableCell>
                    <IconButton color="primary" component="span" aria-label="adaugă înregistrare" onClick={() => onEdit(item._id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="primary" component="span" aria-label="adaugă înregistrare" onClick={() => onDelete(item._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button className={classes.saveButton} variant="contained" color="primary" onClick={() => onAdd(tip)}>
          Adaugă
        </Button>
      </Grid>
    </Grid>
  );
}
