import { getToken } from "../services/keepAuth";
import { EmailTemplate } from "../types";
import { apiBase, checkStatus, getById, customPut, customPost, ApiResponse } from "./utils";

const baseUrl = `${apiBase}/adminApi/emailTemplate`;

export function getEmailTemplateById(idEmailTemplate: string): Promise<ApiResponse<EmailTemplate>> {
  return getById(baseUrl, idEmailTemplate);
}

export function putEmailTemplate(data: EmailTemplate): Promise<ApiResponse<null>> {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export async function putImportCoduri(idEmailTemplate: string, data: FormData): Promise<ApiResponse<null>> {
  const loginData = {
    method: "put",
    headers: {
      azza: getToken() ?? "",
      Accept: "application/json",
    },
    body: data,
  };
  return fetch(`${baseUrl}/importCoduri/${idEmailTemplate}`, loginData).then(checkStatus) as Promise<ApiResponse<null>>;
}

export function postSendTestEmail(idEmailTemplate: string, data: any): Promise<ApiResponse<null>> {
  return customPost(`${baseUrl}/sendTestEmail/${idEmailTemplate}`, data);
}
