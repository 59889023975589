import { Button, Grid, makeStyles, Paper, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import FormInput from "../../Components/FormInput";
import Spinner from "../../Components/Spinner";
import { getCheckCodClient } from "../../utils/api/conturiClienti";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonsContainer: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    // alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
}));

const validateFormData = ({ codClient, nrContract }) => ALFA_NUMERIC_REGEX.test(codClient) && ALFA_NUMERIC_REGEX.test(nrContract);

export default function VerificareSivecoCodClient() {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [infoMsg, setInfoMsg] = useState("");
  const [formData, setFormData] = useState({
    codClient: "",
    nrContract: "",
  });

  const changeFormData = (name, value) => {
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const tryCheckCodClientSiNrContract = async () => {
    try {
      setInfoMsg("Vă rugăm așteptați");
      const apiResponse = await getCheckCodClient(formData);
      if (apiResponse.type)
        if (apiResponse.clientGasit) setInfoMsg("Client găsit cu succes");
        else setInfoMsg("Client negăsit");
      else setInfoMsg("Eroare siveco");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Grid item xs={12} className={classes.buttonsContainer}>
        <Paper className={classes.paper}>
          <form>
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Cod client"
              name="codClient"
              value={formData.codClient}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />
            <FormInput
              className={classes.inputStyle}
              fullWidth
              onChange={changeFormData}
              label="Număr contract"
              name="nrContract"
              value={formData.nrContract}
              required
              regexTest={ALFA_NUMERIC_REGEX}
              helperText={ALFA_NUMERIC_ERROR}
            />
            <Button
              disabled={!validateFormData(formData)}
              className={classes.saveButton}
              variant="contained"
              color="primary"
              onClick={tryCheckCodClientSiNrContract}
            >
              Verifică
            </Button>
          </form>
          <Spinner
            containerStyle={{
              width: 41,
              height: 41,
              margin: "40px auto 0px auto",
            }}
            show={infoMsg === "Vă rugăm așteptați"}
          />
          <div
            style={{
              padding: "30px 0px",
              // marginTop: 32,
              textAlign: infoMsg.length > 44 ? "justify" : "center",
              fontSize: 18,
              color:
                infoMsg === "Vă rugăm așteptați"
                  ? theme.palette.primary.main
                  : infoMsg.includes("succes")
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {infoMsg}
          </div>
        </Paper>
      </Grid>
    </div>
  );
}
