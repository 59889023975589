import { ApiResponse, apiBase, customGet, customPost, customPut, deleteById } from "./utils";

const baseUrl = `${apiBase}/adminApi/acteNormative`;

export type ActeNormative = {
  _id: string;
  tip: number;
  titlu: string;
  link: string;
  tipRaport?: number;
};

export function getAllActeNormative(): Promise<ApiResponse<ActeNormative[]>> {
  return customGet(`${baseUrl}/all`);
}

export function postActNormativ(data: Omit<ActeNormative, "_id">): Promise<ApiResponse<string>> {
  return customPost(baseUrl, data);
}

export function putActNormativ(data: ActeNormative): Promise<ApiResponse<null>> {
  return customPut(`${baseUrl}/${data._id}`, data);
}

export function deleteActNormativById(itemId: string): Promise<ApiResponse<null>> {
  return deleteById(baseUrl, itemId);
}
