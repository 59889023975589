import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TabPanel from "../../Components/TabPanel";
import DeschiderePerioadaAutocitire from "./DeschiderePerioadaAutocitire";
import BineAiVenitEmailTemplate from "./bineAiVenit";
import InviteClientEmailTemplate from "./inviteClient";
import ContSinronizatCuSivecoTemplate from "./contSincronizatCuSiveco";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(4),
    maxWidth: 250,
  },
  modal: {
    position: "absolute",
    width: "100%",
  },
  modalContent: {
    width: "80%",
    margin: "5vh auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  inputStyle: {
    marginTop: theme.spacing(2),
  },
}));

export default function EmailuriAutomatePage() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Email deschidere perioadă autocitire" />
          <Tab label="Bine ai venit - Gazmir.ro" />
          <Tab label="Link crearea cont precompletat" />
          <Tab label="Cont client sincronizat cu siveco" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <DeschiderePerioadaAutocitire />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <BineAiVenitEmailTemplate />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <InviteClientEmailTemplate />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <ContSinronizatCuSivecoTemplate />
      </TabPanel>
    </div>
  );
}
