import { Button, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "notistack";
import React from "react";
import { postImportAccesConsumuri } from "../../utils/api/accesConsumuri";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(1),
    maxWidth: 250,
  },
  fileName: {
    // padding: theme.spacing(1),
    fontSize: 20,
    lineHeight: "48px",
    fontWeight: 500,
  },
  closeContainer: {
    flex: 2,
  },
}));

export default function AccesConsumuriPage() {
  const [file, setFile] = React.useState<File | null>(null);
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const addFisierImport = async () => {
    if (file != null) {
      const data = new FormData();
      data.append("fisierImport", file);
      try {
        const apiResponse = await postImportAccesConsumuri(data);
        if (apiResponse.type) {
          enqueueSnackbar("Importul a fost realizat cu succes!", { variant: "success" });
        } else enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
        console.log(apiResponse);
      } catch (error) {
        enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      } finally {
        removeFile();
      }
    } else if (inputFileRef.current != null) {
      inputFileRef.current.click();
    }
  };

  const changeSelectedFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    console.log(files);
    if (files != null && files.length > 0) setFile(files[0]);
  };

  const removeFile = () => {
    setFile(null);
    if (inputFileRef.current != null) inputFileRef.current.value = "";
  };

  return (
    <Grid container className={classes.column}>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          {file && (
            <div>
              <span className={classes.fileName}>{file.name}</span>
              <IconButton color="primary" aria-label="upload document" component="span" onClick={removeFile}>
                <CancelIcon />
              </IconButton>
            </div>
          )}
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={!file ? <AddCircleIcon /> : <CloudUploadIcon />}
            onClick={addFisierImport}
          >
            {!file ? "Import acces consumuri" : "Incarcă"}
          </Button>
          <form>
            <input
              type="file"
              id="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={changeSelectedFiles}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </form>
        </Paper>
      </Grid>
      {/* <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Button className={classes.button} variant="contained" color="primary" endIcon={<DescriptionIcon />} onClick={downloadEmailuriClienti}>
            Descarcă raportul pentru emailuri
          </Button>
        </Paper>
      </Grid> */}
    </Grid>
  );
}
