import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { postLogin } from "../../utils/api/login";
import { isLoggedIn, saveAdminInfo, saveToken } from "../../utils/services/keepAuth";
import Spinner from "../../Components/Spinner";
import { wait } from "../../utils";
import Copyright from "../../Components/Copyright";
import { appBasePath } from "../../utils/api/utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    // backgroundColor: theme.palette,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn({ setAdminRole }) {
  const theme = useTheme();
  const classes = useStyles();
  const [formData, setFormData] = useState({ email: "", password: "", keepAuth: true });
  const [infoMsg, setInfoMsg] = useState(null);

  useEffect(() => {
    if (isLoggedIn()) navigate(`${appBasePath}/dashboard`);
  });

  const changeFormData = (event) => {
    const { name, value, checked } = event.target;
    // console.log(name, value, checked);
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: name === "keepAuth" ? checked : value,
    }));
  };

  const tryLogin = async () => {
    setInfoMsg("Vă rugăm așteptați");
    try {
      // await wait(3000);
      const { type, data, reason } = await postLogin(formData);
      if (type) {
        setInfoMsg("A-ți fost autentificat cu succes! În câteva momente veți fi redirecționat către contul tău.");
        console.log(data);
        saveToken(data.token, formData.keepAuth);
        saveAdminInfo({
          email: data.email,
          _id: data._id,
          role: data.role,
        });
        setAdminRole(data.role);
        setTimeout(() => {
          navigate(`${appBasePath}/dashboard`);
        }, 500);
      } else
        switch (reason) {
          case 0:
            setInfoMsg("Nu a fost găsit nici un cont pentru adresa de email introdusă");
            break;
          case 1:
            setInfoMsg("Parola introdusă este greșită");
            break;
          case 2:
            setInfoMsg(
              "Contul dumneavoastră a fost blocat temporar din cauză ca ați introdus parola greșit de mai multe ori. Reîncercați în două ore sau resetațivă parola."
            );
            break;
          default:
            break;
        }
    } catch (error) {
      console.error(error);
      setInfoMsg("A apărut o eroare la incercarea de conectare.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={changeFormData}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={changeFormData}
          />
          <FormControlLabel
            control={<Checkbox name="keepAuth" color="primary" />}
            label="Remember me"
            onChange={changeFormData}
            value={formData.keepAuth}
            checked={formData.keepAuth}
          />
          <Button type="button" onClick={tryLogin} fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        {infoMsg && (
          <>
            <Spinner
              containerStyle={{
                width: 41,
                height: 41,
                margin: "40px auto 0px auto",
              }}
              show={infoMsg === "Vă rugăm așteptați"}
            />
            <div
              style={{
                padding: "30px 0px",
                // marginTop: 32,
                textAlign: infoMsg.length > 44 ? "justify" : "center",
                fontSize: 18,
                color:
                  infoMsg === "Vă rugăm așteptați"
                    ? theme.palette.primary.main
                    : infoMsg.includes("succes")
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            >
              {infoMsg}
            </div>
          </>
        )}
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
