import { Button, Grid, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionIcon from "@material-ui/icons/Description";
import { DatePicker } from "@mui/x-date-pickers";
import FileSaver from "file-saver";
import { useSnackbar } from "notistack";
import React from "react";
import { getRaportEmailuriFacturi, postImportEmailuriClienți } from "../../utils/api/transmitereEmailFacturi";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
    maxWidth: 250,
  },
  column: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  importText: {
    padding: 8,
  },
}));

const dataIntaiALunii = new Date();
dataIntaiALunii.setHours(10);
dataIntaiALunii.setDate(1);

export default function ImportExportTransmiteremailuri() {
  const classes = useStyles();
  const [dataEmitere, setDataEmitere] = React.useState<Date>(dataIntaiALunii);
  const [file, setFile] = React.useState<File | null>(null);
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const downloadRaportEmailuriFacturi = async () => {
    try {
      const data = await getRaportEmailuriFacturi({ dataEmitere: dataEmitere });
      if (data) FileSaver.saveAs(data, "raportFacturiEmail");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDataEmitereChange = (date: Date | null) => {
    if (date != null) setDataEmitere(date);
  };

  const addFisierImport = async () => {
    if (file != null) {
      const data = new FormData();

      data.append("importEmailuri", file);
      try {
        const apiResponse = await postImportEmailuriClienți(data);
        if (apiResponse.type) {
          enqueueSnackbar("Importul a fost realizat cu succes!", { variant: "success" });
        } else enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
        console.log(apiResponse);
      } catch (error) {
        enqueueSnackbar("A apărut o eroare la încarcare", { variant: "error" });
      } finally {
        removeFile();
      }
    } else if (inputFileRef.current != null) inputFileRef.current.click();
  };

  const changeSelectedFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files != null && files.length > 0) setFile(files[0]);
  };

  const removeFile = () => {
    setFile(null);
    if (inputFileRef.current != null) inputFileRef.current.value = "";
  };

  return (
    <Grid container className={classes.column}>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography variant="body1" className={classes.importText}>
            Fisierul de import trebuie sa nu conțină duplicate și să aibă următoarea structură: <br /> A - cod client <br /> B - email client <br /> C
            - tip client (pentru notificări)
          </Typography>
          {file && (
            <div>
              <span>{file.name}</span>
              <IconButton color="primary" aria-label="upload document" component="span" onClick={removeFile}>
                <CancelIcon />
              </IconButton>
            </div>
          )}
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={!file ? <AddCircleIcon /> : <CloudUploadIcon />}
            onClick={addFisierImport}
          >
            {!file ? "Import emailuri clienți" : "Incarcă emailuri clienți"}
          </Button>
          <form>
            <input
              type="file"
              id="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={changeSelectedFiles}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </form>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <DatePicker
            // disableToolbar
            views={["year", "month"]}
            openTo="month"
            format="MMM-yyyy"
            label="Lună emitere facturi transmise"
            value={dataEmitere}
            onChange={handleDataEmitereChange}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={<DescriptionIcon />}
            onClick={downloadRaportEmailuriFacturi}
          >
            Descarcă raportul pentru emailuri
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
