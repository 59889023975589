import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import TabPanel from "../../Components/TabPanel";
import LinkInviteForm from "./LinkInvite";
import InviteClientEmailTemplate from "../EmailuriAutomate/inviteClient";

export default function CreareContPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Link invite" />
          {/* <Tab label="Model email invite client" /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <LinkInviteForm />
      </TabPanel>
      {/* <TabPanel value={tabValue} index={1}>
        <InviteClientEmailTemplate />
      </TabPanel> */}
    </div>
  );
}
